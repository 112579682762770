<template>
  <div class="ContactSection">
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Screenshot-2024-02-29-at-09.36.51.png">
    </template>
    <div class="container">
      <h2 class="ContactTitle">Get in touch today</h2>
      <p class="WhiteStandard-text contact-width">To book a tour or enquire about memberships please complete the form below and a member of our team will get in touch.
Alternatively call the studio on 056 0366 2267</p>
      <Form />
    </div>
    <EnquireyBannerTwo />
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Screenshot-2024-02-28-at-13.48.46.png">
    </template>
    <template v-else>
      <GolfParkImages />
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import EnquireyBannerTwo from '@/components/EnquireyBannerTwo.vue'
import GolfParkImages from '@/components/GolfParkImages.vue'
import Form from '@/components/Form.vue'
export default {
  name: 'Contact',
  components: {
    EnquireyBannerTwo,
    GolfParkImages,
    Form
  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'golfpark' + "ocs-studios/wp-json/wp/v2/pages/6"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
.title {
  color: black;
}
.ContactSection {
  background-color: #1A1A1A;
}
.ContactTitle {
  color: #fff;
  font-size: 65px;
  padding-top: 80px;
  font-family: "korolev", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.WhiteStandard-text {
  font-size: 24px;
  color: #fff;
  font-family: "noto-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.contact-width {
  width: 70%;
  text-align: center;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 768px) {
  .ContactTitle {
    color: #fff;
    font-size: 40px;
  }
  .WhiteStandard-text {
    font-size: 20px;
    color: #fff;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .imagesInsert {
    width: 100%;
  }
}
</style>
