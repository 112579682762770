<template>
  <div class="ContactSection">
        <iframe width="100%" height="1000px" src="https://simpark.racecentres.com/hotlapping/94"></iframe>
  </div>
</template>

<script>
export default {
  name: 'Race Analysis',
  components: {
  },
  data: function() {
    return {
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
}
</script>

<style scoped>
.title {
  color: black;
}
.ContactSection {
  background-color: #1A1A1A;
}
.ContactTitle {
  color: #fff;
  font-size: 65px;
  padding-top: 80px;
  font-family: "korolev", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.WhiteStandard-text {
  font-size: 24px;
  color: #fff;
  font-family: "noto-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.contact-width {
  width: 100%;
  text-align: center;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 768px) {
  .ContactTitle {
    color: #fff;
    font-size: 40px;
  }
  .WhiteStandard-text {
    font-size: 20px;
    color: #fff;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .imagesInsert {
    width: 100%;
  }
}
</style>
